<template>
  <div class="col-12 col-lg-4 c g">
    <br><br>
    <div class="card card-body">
        <h4 class="g">
            تسجيل الدخول
        </h4>
        <div v-if="error" class="col-12 text-center alert alert-danger">
            {{ error }}
        </div>
        <div class="form-group g">
          <label for="">اسم المستخدم</label>
          <input type="email"
            class="form-control" v-model="email">
        </div>
        <div class="form-group g">
          <label for="">كلمة المرور</label>
          <input type="password"
            class="form-control" v-model="password">
        </div>
        <div class="form-group g" v-if="otp">
          <label for="">OTP</label>
          <input type="text"
            class="form-control" v-model="code">
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="login()">
                تسجيل الدخول
            </button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            password: null,
            email: null,
            error: null,
            code: "",
            otp: false
        }
    },
    methods: {
        login(){
            var g = this;
            $.post(api + '/admin/login', {
                email: this.email,
                password: this.password,
                code: this.code
            })
            .then(function(r){
                if(r.status != "100"){
                    g.error = "البيانات غير صحيح"
                    if(r?.response?.includes("OTP")){
                        g.otp = true;
                    }
                }else{
                    localStorage.setItem("jwt", r.response)
                    window.location = '/'
                }
            })
            .fail(function(){
                alert("error")
            })
        }
    }
}
</script>

<style>

</style>